import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Access-Control-Allow-Origin': "*"
  }
});

const axiosInstanceCabinet = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_DEFAULT,
  headers: {
    'Access-Control-Allow-Origin': "*"
  }
});

axiosInstanceCabinet.interceptors.request.use(function (config) {
  const token = localStorage.getItem("authAdminToken");
  if (token) {
      config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export { axiosInstanceCabinet, axiosInstance};
